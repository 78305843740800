
import './landingAppealsMap.css';
import React from 'react';
import { useAppContext } from '../../../../store/AppContenxt/AppContenxt';
import { LandingMapWithYMaps as LandingMap } from './components/LandingMap/LandingMap';
import SendMessageButton from '../SendMessageButton/SendMessageButton';

const LandingAppealsMap = () => {
  const { store } = useAppContext();
  return (
    <section className='wrapper'>
      <div className='container'>
        <div className='map-container'>
          <LandingMap region={store?.currentRegion}/>
        </div>
        <div className='button-container'>
          <SendMessageButton/>
        </div>
      </div>
    </section>
  )
};

export default LandingAppealsMap;