export enum EAppealStatus {
    COMPLETED_MODERATOR_DECLINE = 'COMPLETED_MODERATOR_DECLINE',
    COMPLETED_SIGNING_APPROVE_RESPONSE = 'COMPLETED_SIGNING_APPROVE_RESPONSE',
    COMPLETED_EDMS_RESPONSE_SENT_TO_APPLICANT = 'COMPLETED_EDMS_RESPONSE_SENT_TO_APPLICANT',
    COMPLETED_ESCALATE_REJECTED = 'COMPLETED_ESCALATE_REJECTED'
}

export enum EAppealStatusText {
    COMPLETED = 'Рассмотрено',
    IN_PROGRESS = 'В работе'
}

export interface IShortAppeal {
    id: number;
    appealStatus: string;
    subjectName: string;
    description: string;
    answer?: string;
    location?: string;
    stage: string;
    status: {
        status: string;
        statusIconUrl: string;
        title: string;
    }
}

export type TAccordionShortAppeal = IShortAppeal & {isOpen: boolean};

export interface IPoint {
    id: number;
    coordinates: number[];
}

export interface IAppealIds {
    [key: string]: string;
}

export interface IAppealsLocation {
    appealIds: IAppealIds[];
    coordinates: string ;
}

export interface IRegion {
    coordinates: string,
    id: number,
    name: string,
    okato: string
}

export interface IZoom {
    newZoom:number;
    oldZoom:number;
}
