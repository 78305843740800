import menuIcon from '../../../../../../assets/img/new-landing/menu-icon.svg';
import logoIcon from '../../../../../../assets/img/new-landing/logo-icon.svg';
import RegionSelector from '../../../../../../components/RegionSelector/RegionSelector';
import { GOSUSLUGI_LINK } from '../../../../../../constants/links';
import HeaderNav from './HeaderNav/HeaderNav';

import './header.css';

const Header = () => {
  return (
    <header className='header'>
      <div className='container header-container'>
        <div className='settings'>
          <div className='logo'>
            <a rel='noreferrer' target='_blank' href={GOSUSLUGI_LINK}>
              <img
                src={logoIcon}
                alt='ГосУслуги'
              />
            </a>
          </div>
          <div className='region'>
            <RegionSelector showLocationIcon={false} />
          </div>
        </div>
        <HeaderNav/>
      </div>
    </header>
  )
};

export default Header;