import closeIcon from 'src/assets/img/new-landing/close-icon.svg';
import SendMessageButton from '../SendMessageButton/SendMessageButton';

import './LandingBottomModal.css';

interface ILandingBottomModalProps {
  onCloseModal: () => void;
}

const LandingBottomModal = ({onCloseModal} : ILandingBottomModalProps) => {
  return (
    <div className='wrapper bottom-modal'>
      <div className='container bottom-modal-container'>
        <div className='bottom-modal-text'>
          <p>Подавайте жалобы, задавайте вопросы, вносите предложения, чтобы повысить качество жизни</p>
        </div>
        <div className='bottom-modal-button'>
          <SendMessageButton/>
        </div>
        <div className='close-icon'>
          <span onClick={onCloseModal}>
            <img
              src={closeIcon}
              alt='close'
            />
          </span>
        </div>
      </div>
    </div>
  )
};

export default LandingBottomModal;