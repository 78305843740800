import React, { FC, memo } from 'react';
import { CustomArrowProps } from 'react-slick';

import chevronIcon from '../../../../../assets/img/landing/chevron.svg';

interface IProps extends CustomArrowProps {
  buttonClassName: string;
}

export const SampleArrow: FC<IProps> = memo((props) => {
  const { onClick, buttonClassName } = props;
  return (
    <button
      onClick={onClick}
      className={buttonClassName}
    >
      <img src={chevronIcon} />
    </button >
  );
});
