import { useCallback, useEffect, useState } from "react";
import chatIcon from 'src/assets/img/new-landing/chat-icon.svg';
import checkIcon from 'src/assets/img/new-landing/check-icon.svg';
import usersIcon from 'src/assets/img/new-landing/users-icon.svg';
import { api } from "../../../../api/api";
import './landingStatistics.css';

const APPEAL_SERVICE = 'inbox-service/landing/analytics'

const LandingStatistics = () => {
  const [replies, setReplies] = useState(0);
  const [appeals, setAppeals] = useState(0);
  const [votesState, setVotesState] = useState({usersVotes: 0});

  const getUsersData = useCallback(async () => {
    try {
      const response = await api.getStatistic();
      setReplies(response.solvedCount)
      setAppeals(response.inProgressCount);
    } catch (error) {
      console.log(error);
    }
  }, [replies, appeals]);

  const getUsersVotes = useCallback(async () => {
    try {
      const url = `/${APPEAL_SERVICE}`;
      const data = await api.getPollsRequest(url);
      setVotesState(data);
    } catch (error) {
      console.log(error);
    }
  }, [votesState]);

  useEffect(() => {
    getUsersData();
    getUsersVotes();
  }, []);

  return (
    <section className='wrapper statistics-wrapper'>
      <div className='container'>
        <div className='statistics-container'>
          <div className='statistic-block statistic-block_replies'>
            <img
              src={checkIcon}
              alt=''
            />
            <div className='statistic-info'>
              <span className='statistic-title'>{replies?.toLocaleString('ru-RU')}</span>
              <span className='statistic-text'>ответов дано</span>
            </div>
          </div>
          <div className='statistic-block statistic-block_appeals'>
            <img
              src={chatIcon}
              alt=''
            />
            <div className='statistic-info'>
              <span className='statistic-title'>{appeals?.toLocaleString('ru-RU')}</span>
              <span className='statistic-text'>сообщений и обращений в работе</span>
            </div>
          </div>
          <div className='statistic-block'>
            <img
              src={usersIcon}
              alt=''
            />
            <div className='statistic-info statistic-block_votes'>
              <span className='statistic-title'>{votesState?.usersVotes?.toLocaleString('ru-RU')}</span>
              <span className='statistic-text'>человек участвовали в опросах</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default LandingStatistics;
