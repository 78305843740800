import React, { useEffect } from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router, withRouter } from 'react-router-dom';
import Landing from '../pages/Landing/Landing';
import { HotFoodFeedbackDescription } from '../pages/FeedbackDescriptionPage/HotFoodFeedbackDescription/HotFoodFeedbackDescription';
import { AmbulanceFeedbackDescription } from '../pages/FeedbackDescriptionPage/AmbulanceFeedbackDescription/AmbilanceFeedbackDescription';
import { CovidFeedbackDescription } from '../pages/FeedbackDescriptionPage/CovidFeedbackDescription/CovidFeedbackDescription';
import { DepartmentFeedbackDescription } from '../pages/FeedbackDescriptionPage/DepartmentFeedbackDescription/DepartmentFeedbackDescription';
import { GovernmentFeedbackDescription } from '../pages/FeedbackDescriptionPage/GovernmentFeedbackDescription/GovernmentFeedbackDescription';
import { FeedbackFormPage } from '../pages/FeedbackFormPage/FeedbackFormPage';

import {
  LANDING,
  HOT_FOOD_FEEDBACK_DESCRIPTION,
  AMBULANCE_FEEDBACK_DESCRIPTION,
  COVID_FEEDBACK_DESCRIPTION,
  DEPARTMENT_FEEDBACK_DESCRIPTION,
  GOVERNMENT_FEEDBACK_DESCRIPTION,
  FEEDBACK_FORM,
} from '../constants/paths';


const ScrollToTop = withRouter(({ history }: any) => {
  useEffect(() => {
    const removeHistoryListener = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      removeHistoryListener();
    };
  }, [history]);

  return null;
});

const AppRouter = () => {
  return (
      <Router>
        <ScrollToTop/>
        <Switch>
          <Route path={`/${LANDING}`} exact={true} component={Landing} />

          {/* Неиспользуемые роуты после редизайна*/}
          {/* <Route path={`/${LANDING}/${HOT_FOOD_FEEDBACK_DESCRIPTION}`} component={HotFoodFeedbackDescription} />
          <Route path={`/${LANDING}/${AMBULANCE_FEEDBACK_DESCRIPTION}`} component={AmbulanceFeedbackDescription} />
          <Route path={`/${LANDING}/${COVID_FEEDBACK_DESCRIPTION}`} component={CovidFeedbackDescription} />
          <Route path={`/${LANDING}/${DEPARTMENT_FEEDBACK_DESCRIPTION}`} component={DepartmentFeedbackDescription} />
          <Route path={`/${LANDING}/${GOVERNMENT_FEEDBACK_DESCRIPTION}`} component={GovernmentFeedbackDescription} />
          <Route path={`/${LANDING}/${FEEDBACK_FORM}`} component={FeedbackFormPage} /> */}
          <Route path='*' render={() => <Redirect to={`/${LANDING}`} />} />
        </Switch>
      </Router>
  );
}

export default AppRouter;
