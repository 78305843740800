import cx from 'classnames';
import React, { FC, memo, ReactNode, useState } from 'react';
import arrowIcon from 'src/assets/img/new-landing/arrow-icon.svg';
import styles from './UIDropdown.module.css';

interface IUIDropdownProps {
  children: ReactNode;
  title: ReactNode;
  expanded?: boolean;
}

const UIDropdown: FC<IUIDropdownProps> = memo(
  ({
     children,
     title,
     expanded = false
   }) => {
    const [isExpanded, setExpanded] = useState(expanded);

    const onToggle = () => setExpanded((isExpand) => !isExpand);

    return (
      <div
        className={cx(styles.accordion, {
          [styles.open]: isExpanded,
        })}
      >
        <div
          className={cx(styles.headline, styles.arrowRight)}
          onClick={onToggle}
        >
          <div className={cx(styles.title)}>{title}</div>
          <div className={styles.iconWrap}>
            <img
              src={arrowIcon}
              alt=''
            />
          </div>
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    );
  },
);

export default UIDropdown;
