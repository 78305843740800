export const GOSUSLUGI_LINK = 'https://www.gosuslugi.ru';
export const UNIVERSAL_FORM_LINK = '/help/obratitsya_v_pos';
export const GOSUSLUGI_CATEGORIES_LINK = `${GOSUSLUGI_LINK}/category`;
export const GOSUSLUGI_PAY_LINK = 'https://oplata.gosuslugi.ru/pay';
export const GOSUSLUGI_HELP_LINK = `${GOSUSLUGI_LINK}/help`;
export const FORMS_LINK = process.env.REACT_APP_FORMS_LINK || 'https://www.gosuslugi.ru/help';
export const GOSUSLUGI_UNIVERSAL_LINK = `${FORMS_LINK}/obratitsya_v_pos`;
export const IOS_POS_LINK = process.env.REACT_APP_IOS_POS_LINK || 'https://posgosuslugi.page.link/obratitsya_v_pos';
export const ANDROID_POS_LINK = process.env.REACT_APP_ANDROID_POS_LINK || 'https://pos.gosuslugi.ru/help/obratitsya_v_pos';
export const GOSUSLUGI_MINZDRAV_LINK = `${FORMS_LINK}/obratitsya_skoraya`;
export const GOSUSLUGI_PAYMENTS_LINK = `${FORMS_LINK}/obratitsya_viplaty`;
export const GOSUSLUGI_SPORT_LINK = `${FORMS_LINK}/obratitsya_sport`;
export const GOSUSLUGI_VEDOMSTVO_LINK = `${FORMS_LINK}/obratitsya_v_vedomstvo`;
export const GOSUSLUGI_CALLS_LINK = `${FORMS_LINK}/obratitsya_call`;
export const GOSUSLUGI_UNIVERSITY_LINK = `${FORMS_LINK}/obratitsya_v_vuz`;
export const GOSUSLUGI_DOCTOR_LINK = `${FORMS_LINK}/obratitsya_doctor_appointment`;
export const GOSUSLUGI_DRUG_LINK = `${FORMS_LINK}/obratitsya_medicines`;
export const GOSUSLUGI_BENEFIT_LINK = `${FORMS_LINK}/measures`;
export const GOSUSLUGI_MILITARY_LINK = `${FORMS_LINK}/obratitsya_military_recruitment`;
export const GOSUSLUGI_EDUCATION_LINK = `${FORMS_LINK}/obratitsya_achievements_education`;
export const GOSUSLUGI_COLLEGE_LINK = `${FORMS_LINK}/obratitsya_v_college`;
export const GOSUSLUGI_PAYMENTS_MED_LINK = `${FORMS_LINK}/obratitsya_socvyplaty_medrabotnikam`;
export const GOSUSLUGI_PUSHKIN_CARD_LINK = `${FORMS_LINK}/obratitsya_pushkinskaya_karta`;
export const GOSUSLUGI_POSTOFFICE_LINK = `${FORMS_LINK}/obratitsya_postoffice`;
export const GOSUSLUGI_FKGS_LINK = 'https://pos.gosuslugi.ru/lkp/fkgs/?utm_source=fkgs&utm_medium=landing&utm_campaign=banner';
export const LK_LINK = 'https://lk.gosuslugi.ru/settings/account';
export const REGISTRATION_LINK = 'https://esia.gosuslugi.ru/registration/';
export const SIMPLIFIED_LOGIN_LINK = 'https://www.gosuslugi.ru/help/faq/c-1/1';
export const MINZDRAV_LINK = 'https://www.gosuslugi.ru/structure/10000001077';
export const MINZDRAV_ICON_LINK = 'https://gu-st.ru/htdocs/img/structure-logo/img_min_zdrav.png';
export const FIND_SERVICE_LINK = 'https://www.gosuslugi.ru/find_service';
export const FOR_BUSINESS_LINK = 'https://www.gosuslugi.ru/legal-entity';
export const FOR_FOREIGN_CITIZENS_LINK = 'https://www.gosuslugi.ru/foreign-citizen';
export const FOR_PARTNERS_LINK = 'https://partners.gosuslugi.ru/';
export const MAP_LINK = 'https://map.gosuslugi.ru/?layer=co';
export const APPLICATION_LINK = 'ru.gosuslugi.pos';
export const APPLICATION_UNIVERSAL_LINK = 'https://pos.gosuslugi.ru/help/obratitsya_v_pos';
export const GOOGLE_PLAY_APP_LINK = 'https://play.google.com/store/apps/details?id=ru.gosuslugi.pos';
export const APPLE_APP_LINK = 'https://apps.apple.com/ru/app/%D0%B3%D0%BE%D1%81%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8-%D1%80%D0%B5%D1%88%D0%B0%D0%B5%D0%BC-%D0%B2%D0%BC%D0%B5%D1%81%D1%82%D0%B5/id1516448015';
export const APP_GALLERY_LINK = 'https://appgallery.huawei.com/app/C106772807';
export const RUSTORE_LINK = 'https://apps.rustore.ru/app/ru.gosuslugi.pos?utm_source=notification&utm_medium=email&utm_campaign=ru_store';
export const TELEGRAM_ACCOUNT_LINK = 'https://t.me/gosuslugi';
export const VK_ACCOUNT_LINK = 'https://vk.com/gosuslugi';
export const OK_ACCOUNT_LINK = 'https://ok.ru/gosuslugi';
export const RUTUBE_ACCOUNT_LINK = 'https://rutube.ru/channel/23789311/';
export const DISCLAIMER_LINK = 'https://gu-st.ru/content/Rules_for_sending_messages1.pdf';
export const REQUEST_TO_SUPPORT_LINK = 'https://www.gosuslugi.ru/newsearch?control=ask_max_button';
export const REQUEST_TO_DEVELOPERS_LINK = 'https://www.gosuslugi.ru/feedback';
export const REGIONAL_POLLS_LINK ='https://pos.gosuslugi.ru/lkp/polls/?plff%5Blevel%5D=20';
export const ALL_PUBLIC_POLLS_LINK ='https://pos.gosuslugi.ru/lkp/public-discussions/';
export const ALL_POLLS_LINK = 'https://pos.gosuslugi.ru/lkp/polls/';
