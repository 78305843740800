import './ExtraMaterials.css';

type TMaterial = {
    id: number;
    date: string;
    headline: string;
    pageUrl: string;
    imgUrl?: string;
}

type TMaterialsDataMock = {
    head: string;
    materials: TMaterial[];
}

const ExtraMaterials = () => {
    const materialsDataMock:TMaterialsDataMock = {
        head: 'Дополнительные материалы',
        materials: [
            {
                id: 1,
                date: '2 сентября 2022',
                headline: 'Утверждена методика рейтинга регионов по работе на Платформе обратной связи',
                pageUrl: 'https://digital.gov.ru/ru/events/41920/',
                // if the first material has an image, it shows as a major material with an image. Three next materials are shown on the right side
                // imgUrl: '/static/media/phone.ff3c75e4.png',
            },
            {
                id: 2,
                date: '23 августа 2022',
                headline: 'В Госдуму внесен законопроект, который позволит гражданам направлять обращения в органы власти через портал Госуслуг',
                pageUrl: 'https://digital.gov.ru/ru/events/41862/',
                // imgUrl: '/static/media/phone.ff3c75e4.png',
            },
            {
                id: 3,
                date: '21 декабря 2021',
                headline: 'Правительство продлевает и расширяет эксперимент по приёму обращений граждан в госорганы через портал госуслуг',
                pageUrl: 'http://government.ru/news/44169/',
                // imgUrl: '/static/media/phone.ff3c75e4.png',
            },
            // {
            //     id: 4,
            //     date: '13 января 2022',
            //     headline: '4 Интервью Олега Качанова о приложении «Госуслуги Решаем вместе»',
            //     pageUrl: '/',
            //     imgUrl: '/static/media/phone.ff3c75e4.png',
            // },
            // {
            //     id: 5,
            //     date: '13 января 2022',
            //     headline: '5 Интервью Олега Качанова о приложении «Госуслуги Решаем вместе»',
            //     pageUrl: '/',
            //     imgUrl: '/static/media/phone.ff3c75e4.png',
            // },
            // {
            //     id: 6,
            //     date: '13 января 2022',
            //     headline: '6 Интервью Олега Качанова о приложении «Госуслуги Решаем вместе»',
            //     pageUrl: '/',
            //     imgUrl: '/static/media/phone.ff3c75e4.png',
            // },
            // {
            //     id: 7,
            //     date: '13 января 2022',
            //     headline: '7 Интервью Олега Качанова о приложении «Госуслуги Решаем вместе»',
            //     pageUrl: '/',
            //     imgUrl: '/static/media/phone.ff3c75e4.png',
            // },
        ]
    };
    const isImageShown = materialsDataMock.materials[0].imgUrl;


    return (
        <section className='wrapper materials'>
            <div className='container'>
                <h2 className="materials__head">{materialsDataMock.head}</h2>
                <ul className={`materials__list${isImageShown ? ' materials__list_img-shown' : ''}`}>
                    {materialsDataMock.materials.map((material, i) => (
                        <li key={material.id} className="materials__item material">
                            <div className="material__content">
                                {isImageShown && i === 0 && <div className="material__visual">
                                    <div className="material__img" style={{backgroundImage: `url(${material.imgUrl})`}}></div>
                                </div>}
                                <div className="material__text">
                                    <p className="material__date">{material.date}</p>
                                    <a href={material.pageUrl} className="material__headline">{material.headline}</a>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
};

export default ExtraMaterials;