import { Link } from 'react-scroll'

const HeaderNav = () => {
  return (
    <nav className='nav'>
      <ul>
        <li>
          <Link to='help' smooth={true}>
            Помощь
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default HeaderNav
