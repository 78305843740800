import { useEffect, useState } from 'react'

const UAParser = require('ua-parser-js/dist/ua-parser.min')

type TOs = Record<
  | 'isAndroid'
  | 'isIOS'
  | 'isMacOS'
  | 'isWindows'
  | 'isLinux'
  | 'isUbuntu'
  | 'isChromiumOS'
  | 'isDebian',
  boolean
>
type TDevice = Record<
  'isMobile' | 'isTablet' | 'isDesktop' | 'isSmartTv' | 'isConsole' | 'isWearable',
  boolean
>

const BrowserTypes = {
  Chrome: 'Chrome',
  Firefox: 'Firefox',
  Opera: 'Opera',
  Yandex: 'Yandex',
  Safari: 'Safari',
  InternetExplorer: 'Internet Explorer',
  Edge: 'Edge',
  Chromium: 'Chromium',
  Ie: 'IE',
  MobileSafari: 'Mobile Safari',
  EdgeChromium: 'Edge Chromium',
  MIUI: 'MIUI Browser',
  SamsungBrowser: 'Samsung Browser'
} as const

export const OsTypes = {
  IOS: 'iOS',
  Android: 'Android',
  Windows: 'Windows',
  Mac_OS: 'Mac OS',
  Linux: 'Linux',
  Ubuntu: 'Ubuntu',
  Debian: 'Debian',
  Chromium_OS: 'Chromium OS'
} as const

export const DeviceTypes = {
  Mobile: 'mobile',
  Tablet: 'tablet',
  SmartTv: 'smarttv',
  Console: 'console',
  Wearable: 'wearable',
  Embedded: 'embedded',
  Browser: undefined
} as const

const ClientUAInstance = new UAParser()

export function useGetOS(): TOs & TDevice {
  const { type } = ClientUAInstance.getDevice()
  const { name } = ClientUAInstance.getOS()

  const [devices, setDevices] = useState<TDevice>({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    isSmartTv: false,
    isConsole: false,
    isWearable: false
  })

  const [os, setOs] = useState<TOs>({
    isAndroid: false,
    isIOS: false,
    isMacOS: false,
    isWindows: false,
    isLinux: false,
    isUbuntu: false,
    isChromiumOS: false,
    isDebian: false
  })

  useEffect(() => {
    switch (type) {
      case DeviceTypes.Mobile:
        setDevices({ ...devices, isMobile: true })
        break
      case DeviceTypes.Tablet:
        setDevices({ ...devices, isTablet: true })
        break
      case DeviceTypes.SmartTv:
        setDevices({ ...devices, isSmartTv: true })
        break
      case DeviceTypes.Console:
        setDevices({ ...devices, isConsole: true })
        break
      case DeviceTypes.Wearable:
        setDevices({ ...devices, isWearable: true })
        break
      case DeviceTypes.Browser:
        setDevices({ ...devices, isDesktop: true })
        break
      default:
        break
    }
    switch (name) {
      case OsTypes.IOS:
        setOs({ ...os, isIOS: true })
        break
      case OsTypes.Android:
        setOs({ ...os, isAndroid: true })
        break
      case OsTypes.Windows:
        setOs({ ...os, isWindows: true })
        break
      case OsTypes.Mac_OS:
        setOs({ ...os, isMacOS: true })
        break
      case OsTypes.Linux:
        setOs({ ...os, isLinux: true })
        break
      case OsTypes.Ubuntu:
        setOs({ ...os, isUbuntu: true })
        break
      case OsTypes.Chromium_OS:
        setOs({ ...os, isChromiumOS: true })
        break
      case OsTypes.Debian:
        setOs({ ...os, isDebian: true })
        break
      default:
        break
    }
  }, [name, type])

  return { ...os, ...devices }
}
