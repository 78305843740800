import phoneIcon from 'src/assets/img/new-landing/phone-icon.svg';
import mouthpieceIcon from 'src/assets/img/new-landing/mouthpiece-icon.svg';
import peopleIcon from 'src/assets/img/new-landing/people-icon.svg';
import messageIcon from 'src/assets/img/new-landing/message-icon.svg';
import './landingPolls.css';
import {REGIONAL_POLLS_LINK, ALL_PUBLIC_POLLS_LINK, ALL_POLLS_LINK} from "../../../../constants/links";
import {useCallback, useEffect, useState} from "react";
import {useAppContext} from "../../../../store/AppContenxt/AppContenxt";
import {TPolls} from "../../../../types";
import {api} from "../../../../api/api";

const INITIAL_STATE = {
  regionalPolls: [{} as TPolls],
  regionalPollsUrl: '',
  federalPolls: [{} as TPolls],
  federalPollsUrl: '',
  publicDiscussions: [{} as TPolls],
  publicDiscussionsUrl: ''
}
const INBOX_SERVICE = 'inbox-service';

const LandingPolls = () => {
  const [pollsState, setPollsState] = useState(INITIAL_STATE)
  const {store} = useAppContext();

  const getPolls = useCallback(async () => {
    if (store?.currentRegion) {
      const url = `/${INBOX_SERVICE}/landing/events?regionId=${store?.currentRegion.id}`;
      const data = await api.getPollsRequest(url, true);
      setPollsState(data)
    }
  }, [store?.currentRegion])


  useEffect(() => {
    getPolls();
  }, [store?.currentRegion])


  return (
    <section className='wrapper polls-wrapper'>
      <div className='container'>
        <div className='polls-headline'>
          <div className='polls-img'>
            <img
                src={phoneIcon}
                alt=''
            />
          </div>
          <div className='polls-title'>
            <h3>Сделайте свой регион комфортнее</h3>
            <p>Принимайте активное участие в развитии своего региона: голосуйте, делитесь мнением и
                участвуйте в обсуждениях. Предлагайте идеи — они будут учтены. Для этого нужно войти на
                Госуслуги под своим логином и паролем</p>
          </div>
        </div>
        <div className='polls-container'>
          {pollsState && pollsState.regionalPolls.length
            ? <div className='polls-block'>
                <div className='poll'>
                  <div className='poll-headline'>
                    <div className='poll-subheadline'>
                      <img
                        src={mouthpieceIcon}
                        alt=''
                      />
                      <h5>Опросы в вашем регионе</h5>
                    </div>
                    <div className='poll-description'>
                      <p>Ответьте на вопросы, чтобы улучшить жизнь в регионе</p>
                    </div>
                  </div>
                  <div className='polls'>
                    {pollsState.regionalPolls.map((el, i) => (
                      <a
                        href={el.url}
                        key={i}
                        className='polls-list'
                      >
                        {el.title}
                      </a>
                    ))}
                  </div>
                  <div className='show-all'>
                    <a href={REGIONAL_POLLS_LINK}>Посмотреть все</a>
                  </div>
                </div>
              </div>
            : null
          }
          {pollsState && pollsState.publicDiscussions.length
            ? <div className='polls-block'>
                <div className='poll'>
                  <div className='poll-headline'>
                    <div className='poll-subheadline'>
                      <img
                        src={peopleIcon}
                        alt=''
                      />
                      <h5>Публичные слушания</h5>
                    </div>
                    <div className='poll-description'>
                      <p>Оставьте замечания и предложения по проектам муниципальных правовых актов</p>
                    </div>
                  </div>
                  <div className='polls'>
                    {pollsState.publicDiscussions.map((el, i) => (
                      <a
                        href={el.url}
                        key={i}
                        className='polls-list'
                      >
                        {el.title}
                      </a>
                    ))}
                  </div>
                  <div className='show-all'>
                      <a href={ALL_PUBLIC_POLLS_LINK}>Посмотреть все</a>
                  </div>
                </div>
              </div>
            : null
          }
        </div>
        <div className='polls-container'>
          {pollsState && pollsState.federalPolls.length
            ? <div className='polls-block'>
                <div className='poll'>
                  <div className='poll-headline'>
                    <div className='poll-subheadline'>
                      <img
                        src={messageIcon}
                        alt=''
                      />
                      <h5>Всероссийские опросы</h5>
                    </div>
                    <div className='poll-description'>
                      <p>Примите участие в жизни страны</p>
                    </div>
                  </div>
                  <div className='polls'>
                    {pollsState.federalPolls.map((el, i) => (
                      <a
                        href={el.url}
                        key={i}
                        className='polls-list'
                      >
                        {el.title}
                      </a>
                    ))}
                  </div>
                  <div className='show-all'>
                    <a href={ALL_POLLS_LINK}>Посмотреть все</a>
                  </div>
                </div>
              </div>
            : null
          }
        </div>
        {/* @todo: check if this "button" should do something */}
        {/* <div className='hide-polls'>
          <a href='#'>Скрыть</a>
        </div> */}
      </div>
    </section>
  )
};

export default LandingPolls;