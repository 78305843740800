import phone from '../../../../assets/img/new-landing/phone.png';
import SendMessageButton from '../SendMessageButton/SendMessageButton';
import './landingBanner.css';

const LandingBanner = () => {
  return (
    <section className='wrapper banner-wrapper'>
      <div className='container'>
        <div className="banner-container">
          <div className='info'>
            <h2>Сообщите о любой проблеме и получите ответ от органов власти</h2>
            <p>«Госуслуги Решаем вместе» — приложение для решения проблем. Подавайте жалобы, задавайте вопросы, вносите предложения, участвуйте в опросах и голосованиях, чтобы повысить качество жизни. Это просто и быстро</p>
            <SendMessageButton/>
          </div>
          <div className='image'>
            <img
              src={phone}
              alt=''
            />
          </div>
        </div>
      </div>
    </section>
  )
};

export default LandingBanner;
