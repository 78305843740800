import React, {
    createRef,
    RefObject,
    ReactNode,
    PureComponent,
} from 'react';
import ReactResizeDetector from 'react-resize-detector';
import ScrollBar from 'react-perfect-scrollbar';

import 'react-perfect-scrollbar/dist/css/styles.css';

interface IUIScrollBarProps {
    children: ReactNode;
    className?: string;
}

// @todo: проследить за ишью https://github.com/mdbootstrap/perfect-scrollbar/issues/920
// @todo: пока что костыль checkHeight.

export class UIScrollBar extends PureComponent<IUIScrollBarProps> {
    private scrollRef: RefObject<ScrollBar & { updateScroll: () => void }> = createRef();
    public onContentResize = () => {
        if (this.scrollRef.current) {
            this.scrollRef.current.updateScroll();
        }
    };
    public checkHeight = (elem: HTMLElement) => {
        const style = window.getComputedStyle(elem);
        const maxHeight = style.getPropertyValue('max-height');
        const height = style.getPropertyValue('height');
        const maxDifference = 5; // примерная разница высот при максимальном скролле вниз
        if(parseFloat(maxHeight) - parseFloat(height) < maxDifference) {
            this.onContentResize();
        }
    };
    public render() {
        const {
            children,
            className,
        } = this.props;
        return (
            <ScrollBar
                className={className}
                ref={this.scrollRef}
                onScrollDown={this.checkHeight}
            >
                <ReactResizeDetector
                    handleHeight={true}
                    onResize={this.onContentResize}
                />
                {children}
            </ScrollBar>
        );
    }
}
