import React from 'react';
import Slider from 'react-slick';
import cx from 'classnames';
import { SampleArrow } from './components/SampleArrow/SampleArrow';

import landingStyles from '../../../pages/Landing/Landing.module.css';
import styles from './UICarousel.module.css';

interface IProps {
  title?: string;
  dots?: boolean;
  arrows?: boolean;
  arrowsPosition?: 'bottom' | 'sides';
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  dotsClass?: string;
  infinite?: boolean;
  swipeToSlide?: boolean;
  touchThreshold?: number;
  edgeFriction?: number;
  centerMode: boolean;
  variableWidth: boolean;
}

export class UICarousel extends React.PureComponent<IProps> {
  render() {
    const {
      title,
      dots = true,
      infinite = true,
      speed = 500,
      dotsClass = 'carousel-dots',
      arrows = true,
      arrowsPosition = 'bottom',
      slidesToShow = 4,
      slidesToScroll = 1,
      children,
      swipeToSlide = true,
      touchThreshold = 8,
      edgeFriction = 0,
      centerMode = false,
      variableWidth = false,
    } = this.props;

    return (
      <div className={styles.carouselContainer}>
        {title && <h2 className={landingStyles.blockTitle}>{title}</h2>}
        <Slider
          className='carousel-container'
          dots={dots}
          infinite={infinite}
          speed={speed}
          arrows={arrows}
          nextArrow={<SampleArrow buttonClassName={cx('carousel-icon', {'carousel-icon-side': arrowsPosition === 'sides'})} />}
          prevArrow={<SampleArrow buttonClassName={cx('carousel-icon-reversed', {'carousel-icon-side': arrowsPosition === 'sides'})} />}
          dotsClass={dotsClass}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToScroll}
          swipeToSlide={swipeToSlide}
          touchThreshold={touchThreshold}
          edgeFriction={edgeFriction}
          centerMode={centerMode}
          variableWidth={variableWidth}
        >
          {children}
        </Slider>
      </div>
    );
  }
}
