import { isAndroid, isIOS } from "react-device-detect";
import {
  GOSUSLUGI_UNIVERSAL_LINK,
  APPLICATION_UNIVERSAL_LINK,
  APPLICATION_LINK,
  APPLE_APP_LINK,
  ANDROID_POS_LINK,
  IOS_POS_LINK
} from 'src/constants/links';

export function onRedirectToForm() {
  if (isAndroid) {
    // window.location.replace(`intent://pos.gosuslugi.ru/#Intent;scheme=https;package=${APPLICATION_LINK};end`);
    window.location.replace(APPLICATION_UNIVERSAL_LINK);
  } else if (isIOS) { 
    window.location.replace(`${APPLICATION_LINK}://`);

    setTimeout(() => {
      window.location.replace(APPLE_APP_LINK);
    }, 3000);
  } else {
    window.location.replace(GOSUSLUGI_UNIVERSAL_LINK);
  }
}

export const getPOSLink = () => {
  if (isAndroid) {
    return ANDROID_POS_LINK;
  } else if (isIOS) {
    return IOS_POS_LINK;
  } else {
    return GOSUSLUGI_UNIVERSAL_LINK;
  }
};
