import { useEffect, useState } from 'react'
import { useWindowSize } from 'src/hooks/useWindowResize'
import { UICarousel } from '../../../../components/ui/UICarousel/UICarousel'
import CarouselItem from './components/CarouselItem/CarouselItem'

import './landingCarousel.css'
import { SLIDES } from './slides'

const LandingCarousel = () => {
  const [width] = useWindowSize()
  const [slides, setSlides] = useState(0)
  const [showArrow, setShowArrow] = useState(true)
  const [centerMode, setCenterMode] = useState(false)
  const [variableWidth, setVariableWidth] = useState(false)

  useEffect(() => {
    if (width < 520) {
      setCenterMode(false)
      setVariableWidth(false)
    }
    if (width < 767 && slides !== 1) {
      setSlides(1)
      setCenterMode(true)
      setVariableWidth(true)
    } else if (width < 1024 && width >= 767 && slides !== 2) {
      setSlides(2)
      setCenterMode(false)
      setVariableWidth(false)
    } else if (width >= 1024 && slides !== 3) {
      if (!showArrow) {
        setShowArrow(true)
      }
      setSlides(3)
      setCenterMode(false)
      setVariableWidth(false)
    }
  }, [width])

  return (
    <section className="landing-carousel wrapper carousel-wrapper">
      <div className="container">
        <div className="carousel-headline">
          <h3>Расскажите, что волнует</h3>
          <p>
            Решить проблему можно не только через приложение, но и через специальную форму — найдите нужный баннер и
            напишите о своей проблеме
          </p>
          <p>
            Проблему рассмотрит профильный орган власти или администрация муниципалитета, отвечающие за решение этих
            вопросов. Без ответа оно не останется — ведомства обязаны дать ответ в течение 30 дней. Некоторые проблемы
            решаются быстрее: в срок до 10 дней
          </p>
          <p>Вы сможете оценить результат</p>
        </div>
        <div className="carousel">
          <UICarousel
            arrows={showArrow}
            arrowsPosition="sides"
            slidesToShow={slides}
            slidesToScroll={1}
            dots={false}
            centerMode={centerMode}
            variableWidth={variableWidth}>
            {SLIDES.map((slide) => (
              <CarouselItem key={slide.id} {...slide} />
            ))}
          </UICarousel>
        </div>
      </div>
    </section>
  )
}

export default LandingCarousel
