import React, { useCallback, useEffect, useState } from 'react';
import { YMapsApi } from 'react-yandex-maps';

export const useFetchLocation = (ymaps: YMapsApi) => {
  const [response, setResponse] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {

    if (ymaps) {
      try {
        setIsLoading(true);
        const data = await ymaps.geolocation.get({
          provider: 'yandex',
          autoReverseGeocode: true,
        });
        const regionName = data?.geoObjects?.get(0).getAdministrativeAreas()[0] || '';
        setResponse(regionName);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
  }, [ymaps]);

  useEffect(() => {
    fetchData();
  }, [ymaps, fetchData]);

  return { response, isLoading };
};
