import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {
  Clusterer,
  GeolocationControl,
  Map,
  MapState,
  MapStateBounds, ObjectManager,
  Placemark,
  withYMaps,
  ZoomControl
} from 'react-yandex-maps';
import {api} from '../../../../../../api/api';
import {UIPopup} from '../../../../../../components/ui/UIPopup/UIPopup';
import styles from './LandingMap.module.css';
import {
  IAppealIds,
  IAppealsLocation,
  IPoint,
  IRegion,
  IShortAppeal,
  IZoom
} from "../../../../../../types/LandingMapTypes";
import pin from '../../../../../../assets/img/landing/ymapsPin.svg';
import pinSelected from '../../../../../../assets/img/landing/ymapsPinSelected.svg';
import cluster from '../../../../../../assets/img/landing/ymapsCluster.svg';
import clusterSelected from '../../../../../../assets/img/landing/ymapsClusterSelected.svg';
import {useFetchRegions} from "../../../../../../hooks/useFetchRegions";
import {geometry} from "yandex-maps";
import { exceptionsRegionsNames } from 'src/constants/exceptions-regions';

interface AnyObject {
  [key: string]: any;
}

const createdAtInterval = {
  from: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString(),
  to: new Date().toISOString()
}

const DEFAULT_MAP_STATE: MapState = {
  bounds: [[50.1, 30.2],[60.3, 20.4]] // границы Москвы
};

const DEFAULT_SELECTED_GEOMETRY = [[0,0],[0,0]];

const MAP_HEIGHT = 430;

// границы РФ, ограничения видимой части карт number[][]
const MAP_RESTRICTIONS: AnyObject = [
  [82, 19],
  [41, -169]
];

const DEFAULT_REGION: IRegion = {
  coordinates: "55.755864,37.617698",
  id: 80,
  name: "Москва",
  okato: "45"
}

const DEFAULT_ZOOM: IZoom = { newZoom:0, oldZoom:0 };
const MAX_ZOOM = 21;


const mapOptions: AnyObject = {restrictMapArea: MAP_RESTRICTIONS, yandexMapDisablePoiInteractivity: true};
const mapRef: any = React.createRef();

const LandingMap: FC<any> = React.memo((props) => {
  const [mapState, setMapState] = useState(DEFAULT_MAP_STATE);
  const [appeals, setAppeals] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [appealItems, setAppealItems] = useState<IShortAppeal[] | []>([]);
  const [allRegions, setRegionsList] = useState([]);
  const { ymaps, region } = props;
  const selectedRegion = region ? region : DEFAULT_REGION;
  const [isShowMap, setShowMap] = useState(false);
  const [YRegions, setYRegions] = useState<any[]>([]);
  const [centerMap, setCenterMap] = useState(DEFAULT_SELECTED_GEOMETRY)
  const [zoomInfo, setZoomInfo] = useState(DEFAULT_ZOOM);

  const [selectedObjectId, setSelectedObjectId] = useState<number>(0);
  const [currentObjectTarget, setCurrentObjectTarget] = useState<any>(undefined);
  const [selectedClusterId, setSelectedClusterId] = useState<string>('');
  const [currentClusterTarget, setCurrentClusterTarget] = useState<any>(undefined);

  const getYmapsRegions = useCallback(async () => {
    try {
      const {features: featuresRU} = await ymaps.borders.load('RU', {
        lang: "ru",
        disputedBorders: "RU",
        quality: 0
      })
      const {features: featuresUA} = await ymaps.borders.load('UA', {
        lang: 'ru',
        quality: 0
      })
      const lostRegions = featuresUA
        .filter((region: any) => exceptionsRegionsNames.includes(region.properties.name))
        // Не удалять, пока ДНР и ЛНР не будут поддерживаться яндекс картами.
        // .map((region: any) => ({
        //   ...region,
        //   properties: {
        //     ...region.properties,
        //     iso3166: 'RU',
        //     neighbors: [],
        //     parents: [
        //       {
        //         delta: -2,
        //         iso3166: 'RU'
        //       }
        //     ]
        //   }
        // }))
      const regs = [...featuresRU, ...lostRegions]
      // такая реализация,потому что дальнейшее формирование регионов идет от названий 'Луганская область','Донецкая область',
      // но яндекс карта воспринимает только 'Донецкая Народная Республика','Луганская Народная Республика'
      regs[88].properties.name = 'Донецкая Народная Республика'
      regs[85].properties.name = 'Луганская Народная Республика'
      setYRegions(regs || [])
    } catch (e) {
      console.log(e);
    }
  }, [ymaps])

  const getAllRegions = async () => {
    const data = await api.getRegions();
    const regions = data?.map((el:any) => el.id)
    setRegionsList(regions)
  }

  useEffect(() => {
    getAllRegions();
  },[])

  useEffect(() => {
    if (ymaps) {
      getYmapsRegions();
    }
  }, [ymaps, getYmapsRegions]);

  useEffect(() => {

    // Показ региона РФ

    if (selectedRegion && YRegions.length && ymaps) {
      setPopupOpen(false);
      const regionShow: any = YRegions.find((region: any)=> region?.properties?.name===selectedRegion.name);

      /*
       от -90 до +90 ограничения широты. от -180 до +180 ограничения долготы
      * */

      const allRegionCoordinates =  regionShow?.geometry?.coordinates;
      let regionBorders = [];
        if (allRegionCoordinates.length===1) {      // когда регион не имеет отдельных островов или анклавов
          regionBorders = regionShow?.geometry?.coordinates[0];
        } else {  // кейс когда регион имеет прерывистые границы - собираем все точки в один массив
          regionShow.geometry.coordinates.map((v: [] ) => v.map((x: [])=> {regionBorders.push(x); return x }))
        }

      if (regionBorders) {
        let topPoint = 0;
        let bottomPoint = 0;
        let leftPoint = 0;
        let rightPoint = 0;

        // берём крайние координаты региона

        regionBorders.forEach((point: number[]) => {
          let latitude = point[0]
          let longitude = point[1]

          topPoint = topPoint ? Math.max(latitude, topPoint) : latitude;
          bottomPoint = bottomPoint ?  Math.min(latitude , bottomPoint) : latitude;
          rightPoint = rightPoint ? Math.max(longitude , rightPoint ) : longitude;
          leftPoint = leftPoint ? Math.min(longitude , leftPoint) : longitude;
        });

        const value: MapStateBounds = {
          bounds: [[topPoint, leftPoint],[bottomPoint, rightPoint]],
        }
        setMapState(value);
        !isShowMap && setShowMap(true);
      }
    }
  }, [selectedRegion, YRegions, ymaps, isShowMap]);

  const getAppeals = useCallback(
    async (coords: number[][], regions: any[]): Promise<void> => {
      try {
        if (regions.length) {
          const data = await api.getAppealsMap(coords)
          if (data && data.length) {
            setAppeals(
              data.map((item: any) => ({
                type: 'Feature',
                id: item.id,
                geometry: {
                  type: 'Point',
                  coordinates: [item.lat, item.lon]
                  // coordinates: [item.lat.toFixed(4), item.lon.toFixed(4)]
                }
              }))
            )
          }
        }
      } catch (e) {
        console.warn(e)
      }
    },[appeals]
  );

  useEffect(() => {   // первоначальная загрузка аппилов
    if (isShowMap && allRegions) {
      getAppeals(mapState.bounds ,allRegions)
      setCenterMap(mapState.bounds)
    }
  }, [isShowMap]);

  /* Проверка необходимости запроса новых аппилов */
  const checkUpdateAppeals = (newCenter: number[]) => {
    return !((centerMap[0][0] < newCenter[0] && newCenter[0] < centerMap[1][0])
            &&(centerMap[0][1] < newCenter[1] && newCenter[1] < centerMap[1][1]))
  }


  const onChange = (e: any) => {
      const newBounds = e.originalEvent.newBounds;
      setZoomInfo({newZoom: e.originalEvent.newZoom, oldZoom: e.originalEvent.oldZoom })
      const check = checkUpdateAppeals(e.originalEvent.newCenter)
      if (check || e.originalEvent.newZoom < e.originalEvent.oldZoom) {
          setCenterMap(newBounds)
          getAppeals(newBounds, allRegions);
      }
  };


  const clearSelectedObjects = () => {
    if (currentObjectTarget) {
      currentObjectTarget.setObjectOptions(selectedObjectId, {
        iconImageHref: pin,
      });
      setSelectedObjectId(0);
      setCurrentObjectTarget(undefined);
    }
    if (currentClusterTarget) {
      currentClusterTarget.setClusterOptions(selectedClusterId, {
        clusterIcons: [{
          href: cluster,
          size: [40, 40],
          offset: [-20, -20]
        }],
      });
      setSelectedClusterId('');
      setCurrentClusterTarget(undefined);
    }
  }

  const closePopup = () => {
    clearSelectedObjects();
    setPopupOpen(false);
    setAppealItems([]);
  };

  const fetchAppealsByIds = async(ids: number[]): Promise<any> => {
    try {
      const appeals = await api.getAppealsByRepeatIds({ids});
      const possibleData = appeals.content || appeals
      const data = possibleData.map((data:any)=>{
        return {
          ...data,
          location: data.location.replace(/украина /i,'')
        }
      })
      setAppealItems(data);
      setPopupOpen(true);
    }catch(e) {
      console.log(e);
    }
  }

  const onClusterClickHandler = async (e: any) => {
    const clickedFeatures = e._sourceEvent._sourceEvent.originalEvent.overlay._data?.features;
    const objectId = e.get('objectId');
    if (clickedFeatures) {
      const coordsSet = new Set();
      clickedFeatures.forEach((feature: any) => coordsSet.add(JSON.stringify(feature.geometry.coordinates)));
      if (coordsSet.size === 1 && zoomInfo.newZoom === MAX_ZOOM || zoomInfo.newZoom === MAX_ZOOM) {
        clearSelectedObjects();
        e.originalEvent.currentTarget.clusters.setClusterOptions(objectId, {
          clusterIcons: [{
            href: clusterSelected,
            size: [40, 40],
            offset: [-20, -20]
          }],
        });
        setSelectedClusterId(objectId);
        setCurrentClusterTarget(e.originalEvent.currentTarget.clusters);

        const featuresIds = clickedFeatures.map((feat: any) => feat.id);
        fetchAppealsByIds(featuresIds);
      }
    } else if (e._sourceEvent._sourceEvent.originalEvent.overlay._data?.id) {
      clearSelectedObjects();
      e.originalEvent.currentTarget.objects.setObjectOptions(objectId, {
        iconImageHref: pinSelected,
      });
      setSelectedObjectId(objectId);
      setCurrentObjectTarget(e.originalEvent.currentTarget.objects);

      fetchAppealsByIds([e._sourceEvent._sourceEvent.originalEvent.overlay._data?.id]);
    }
  }

  return (
    <div>
      {isShowMap &&
        <div className={styles.mapWrapper} >
          <div className={styles.headline}>
            <h3>Узнайте, о чём спрашивают в вашем регионе</h3>
            <p>На карте вы можете посмотреть все публичные сообщения и обращения региона — узнать, что волнует ваших соседей или жителей других регионов</p>
          </div>
          <Map
            state={mapState}
            instanceRef={mapRef}
            style={{
              width: '100%',
              height: MAP_HEIGHT,
              position: 'relative',
              boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
              borderRadius: '12px',
            }}
            // modules={["layout.Image"]}
            options={mapOptions}
            onBoundschange={onChange}
            className={styles.mapBlock}
          >
            <ZoomControl options={
              { size: 'small',
                position: {
                  bottom: 90,
                  right: 50
                },
                height: 0,
              }
            } />
            <GeolocationControl options={
              { position: {
                  bottom: 40,
                  right: 37
                }
              }
            } />
            <ObjectManager
                features={appeals}
                modules={['objectManager.addon.objectsBalloon', 'objectManager.addon.objectsHint']}
                clusters={{
                  preset: 'islands#invertedDarkBlueClusterIcons',
                  clusterIcons: [{
                    href: cluster,
                    size: [30, 30],
                    offset: [-15, -15],
                  },{
                    href: cluster,
                    size: [40, 40],
                    offset: [-20, -20],
                  },{
                    href: cluster,
                    size: [50, 50],
                    offset: [-25, -25],
                  },{
                    href: cluster,
                    size: [60, 60],
                    offset: [-30, -30],
                  },{
                    href: cluster,
                    size: [70, 70],
                    offset: [-35, -35],
                  }],
                  clusterNumbers: [10, 100, 1000, 10000]
                }}
                objects={{
                  openBalloonOnClick: true,
                  preset: 'islands#greenDotIcon',
                  iconLayout: 'default#image',
                  iconImageHref:  pin,
                }}
                options={{
                  clusterize: true,
                  gridSize: 256,
                }}
                onClick={onClusterClickHandler}
            />
              {
                isPopupOpen ? (
                  <div className={styles.popupsWrapper}>
                      <UIPopup
                        appealItems={appealItems}
                        onClosePopup={closePopup}
                      />
                  </div>
                ) : null
              }
          </Map>
        </div>
      }
    </div>
  );
});

export const LandingMapWithYMaps = withYMaps(LandingMap, true, ['suggest', 'borders', 'Placemark', 'geoQuery', 'geoObject.addon.balloon', 'geoObject.addon.hint']);
