import { useCallback, useEffect, useState } from 'react';

import { api } from '../api/api';

import { TRegionDTO } from '../types';

export const useFetchRegions = () => {
    const [response, setResponse] = useState<null|TRegionDTO[]>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const data = await api.getRegions();
            setResponse(data);
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }, [])

    useEffect(() => {
        fetchData();
    }, []);

    return { response, isLoading };
};
