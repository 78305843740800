import React, { ComponentType } from 'react';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

import './layout.css';

interface ILayoutProps {
  onHelpClick: () => void;
}

const LandingLayout = (Component: ComponentType, props?: ILayoutProps) => {
  return (
    <main className='layout'>
      <Header/>
      <div className='content'>
        <Component/>
      </div>
      <Footer/>
    </main>
  )
};

export const withLandingLayout = (Component: ComponentType, props?: ILayoutProps) => () => LandingLayout(Component, props);