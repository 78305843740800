import cx from 'classnames';
import './carouselItem.css';

interface ICarouselItemProps {
  title: string;
  text?: string;
  img: string;
  to: string;
  subTitle?: string;
  cardBackgroundColor?: string;
  textColorLight?: boolean;
}

const CarouselItem = ({title, text, to, img, subTitle, cardBackgroundColor, textColorLight} : ICarouselItemProps) => {
  return (
    <a href={to} rel='noreferrer' target='_blank' className='item' draggable="false" style={{
      background: `${cardBackgroundColor || '#FFFFFF'}`
    }}>
      <div className='item-container' style={{
        backgroundImage: `url(${img})`
      }}>
        <div className='item-content' style={{
          justifyContent: `${subTitle ? 'space-between' : 'flex-start'}`
        }}>
          <span className={cx('item-title', {'color-light': textColorLight})}>{title}</span>
          {text && <span className={cx('item-text', {'color-light': textColorLight})}>{text}</span>}
          {subTitle && <span className={cx('item-title', {'color-light': textColorLight})}>{subTitle}</span>}
        </div>
      </div>
    </a>
  )
};

export default CarouselItem;