import {FC, useCallback} from "react";
import styles from './UIAccordion.module.css';
import {IconDropdown} from "../../icons/IconDropdown";
import {IconDropdownUp} from "../../icons/IconDropdownUp";
import {EAppealStatusText, TAccordionShortAppeal} from "../../../types/LandingMapTypes";

interface IAccordionProps {
    appeal: TAccordionShortAppeal;
    statusText: EAppealStatusText;
    onToggle: (id: number) => void;
}

export const AccordionItem: FC<Readonly<IAccordionProps>> = ({onToggle, appeal, statusText}) => {
    const onChevronClick = useCallback(() => {
        onToggle(appeal.id);
    },[onToggle, appeal]);

    const inProcess = appeal.stage === 'В работе'

    return (
        <div className={styles.accordion}>
            <div className={styles.accordionButton}>
                <div className={styles.accordionTitle}>
                    <div className={styles.accordionTitleCategory}>{appeal.subjectName}</div>
                    <div className={styles.accordionTitleStatus}>
                        <span className={inProcess
                            ? styles.accordionTitleStatusIndicator : styles.accordionTitleStatusIndicatorCompleted} />
                                {appeal.stage}
                    </div>
                </div>
                <div className={styles.accordionTitleIcon} onClick={onChevronClick}>
                    {appeal.isOpen ? <IconDropdownUp/> : <IconDropdown/>}
                </div>
            </div>
            {appeal.isOpen && (<div className={styles.accordionDropdown}>
                <div className={styles.accordionDropdownTitle}>Обращение:</div>
                <div className={styles.accordionDropdownContent}>{appeal.description}</div>
                {!inProcess && appeal.answer && (
                  <>
                    <div className={styles.accordionDropdownTitle}>Ответ:</div>
                    <div dangerouslySetInnerHTML={{ __html: appeal.answer }} />
                  </>
                )}
            </div>)}
        </div>
    );
};