import { getPOSLink } from 'src/utils/redirect';
import './sendMessageButton.css'

const SendMessageButton = () => {
  // Сергей, 4:21 PM
  //   В целом да, планировалось такое
  //   Но раз уж ты рядом убери пожалуйста
  //   Чисто ссылку на форму оставь

  // Сергей, 4:29 PM
  //   Мы с Альбиной договорились, что уберём

  // const link =
  //   isIOS || isMacOS ? APPLE_APP_LINK : isAndroid ? GOOGLE_PLAY_APP_LINK : UNIVERSAL_FORM_LINK

  return (
    <a className='send-message-button' href={getPOSLink()} target='_blank' rel='noreferrer'>
      Написать
    </a>
  )
}

export default SendMessageButton
