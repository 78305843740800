import telegramIcon from 'src/assets/img/new-landing/telegram-icon.svg';
import vkIcon from 'src/assets/img/new-landing/vk-icon.svg';
import okIcon from 'src/assets/img/new-landing/ok-icon.svg';
import rutubeIcon from 'src/assets/img/new-landing/rutube-icon.svg';
import {
  LK_LINK,
  FIND_SERVICE_LINK,
  FOR_BUSINESS_LINK,
  FOR_FOREIGN_CITIZENS_LINK,
  FOR_PARTNERS_LINK,
  GOSUSLUGI_HELP_LINK,
  MAP_LINK,
  REGISTRATION_LINK,
  TELEGRAM_ACCOUNT_LINK,
  VK_ACCOUNT_LINK,
  OK_ACCOUNT_LINK,
  RUTUBE_ACCOUNT_LINK
} from '../../../../../../constants/links';

import './footer.css';

const Footer = () => {
  return (
    <footer className='wrapper footer-wrapper'>
      <div className='container footer-container'>
        <ul>
          <li><a rel='noreferrer' target='_blank' href={LK_LINK}>Личный кабинет</a></li>
          <li><a rel='noreferrer' target='_blank' href={FIND_SERVICE_LINK}>Как найти услугу</a></li>
          <li><a rel='noreferrer' target='_blank' href={REGISTRATION_LINK}>Регистрация на Госуслугах</a></li>
          <li><a rel='noreferrer' target='_blank' href={GOSUSLUGI_HELP_LINK}>Помощь</a></li>
        </ul>
        <ul>
          <li><a rel='noreferrer' target='_blank' href={FOR_BUSINESS_LINK}>Бизнесу</a></li>
          <li><a rel='noreferrer' target='_blank' href={FOR_FOREIGN_CITIZENS_LINK}>Иностранцам</a></li>
          <li><a rel='noreferrer' target='_blank' href={FOR_PARTNERS_LINK}>Партнёрам</a></li>
        </ul>
        <ul>
          <li><a rel='noreferrer' target='_blank' href={GOSUSLUGI_HELP_LINK}>Контакты</a></li>
          <li><a rel='noreferrer' target='_blank' href={MAP_LINK}>Карта центров обслуживания</a></li>
        </ul>
        <ul className='social-links'>
          <li>
            <a rel='noreferrer' target='_blank' href={TELEGRAM_ACCOUNT_LINK}>
              <img
                src={telegramIcon}
                alt=''
              />
            </a>
          </li>
          <li>
            <a rel='noreferrer' target='_blank' href={VK_ACCOUNT_LINK}>
              <img
                src={vkIcon}
                alt=''
              />
            </a>
          </li>
          <li>
            <a rel='noreferrer' target='_blank' href={OK_ACCOUNT_LINK}>
              <img
                src={okIcon}
                alt=''
              />
            </a>
          </li>
          <li>
            <a rel='noreferrer' target='_blank' href={RUTUBE_ACCOUNT_LINK}>
              <img
                src={rutubeIcon}
                alt=''
              />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
};

export default Footer