import React, {FC, memo, useCallback, useEffect, useMemo, useState} from 'react';
import styles from './UIPopup.module.css';
import {IconClose} from "../../icons/IconClose";
import {IconBluePin} from "../../icons/IconBluePin";
import {AccordionItem} from "../UIAccordion/UIAccordion";
import {EAppealStatus, EAppealStatusText, IShortAppeal, TAccordionShortAppeal} from "../../../types/LandingMapTypes";
import {declOfNum} from "../../../utils/utils";

const appealTextVariants = ['обращение', 'обращения', 'обращений'];

interface IProps {
  onClosePopup: () => void;
  appealItems: IShortAppeal[];
}

export const UIPopup: FC<IProps> = memo(({
  onClosePopup,
  appealItems,
}) => {
  const [appeals, setAppeal] = useState<TAccordionShortAppeal[]>([]);

  useEffect(() => setAppeal(appealItems.map(item => ({...item, isOpen: false}))), [appealItems]);

  const toggleAccordion = useCallback((id: number): void => {
      const newAppealsState = appeals.map(appeal => appeal.id === id ? ({...appeal, isOpen : !appeal.isOpen}) : ({...appeal, isOpen : false}));
      setAppeal(newAppealsState);
  }, [appeals]);

  const popupStatusText = useCallback(
      (appealStatus: string): EAppealStatusText=> {
        switch (appealStatus){
          case EAppealStatus.COMPLETED_MODERATOR_DECLINE:
          case EAppealStatus.COMPLETED_SIGNING_APPROVE_RESPONSE:
          case EAppealStatus.COMPLETED_EDMS_RESPONSE_SENT_TO_APPLICANT:
          case EAppealStatus.COMPLETED_ESCALATE_REJECTED:
            return EAppealStatusText.COMPLETED
          default:
            return EAppealStatusText.IN_PROGRESS
        }
      },
      [],
  );

  return (
    <>
      <div className={styles.popup} >
        <div className={styles.popupHeader}>
          <div className={styles.popupHeaderText}>{`${appeals.length} ${declOfNum(appeals.length, appealTextVariants)} по этому адресу`}</div>
          <div className={styles.popupClose} onClick={onClosePopup}>
            <IconClose/>
          </div>
        </div>
        {appeals.length && appeals[0].location ?
            <div className={styles.popupAddressContainer}>
              <IconBluePin />
              <div className={styles.popupAddress}>{appeals[0].location}</div>
            </div> :
        null}
        <div className={styles.popupAppeals}>
          {appeals.map(appeal => (
              <AccordionItem
                  key={appeal.id}
                  onToggle={toggleAccordion}
                  appeal={appeal}
                  statusText={popupStatusText(appeal.appealStatus)}
              />
          ))}
        </div>
      </div>
    </>
  )
});

UIPopup.displayName = 'UIPopup';