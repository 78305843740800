import { useState } from 'react';
import { withLandingLayout } from './components/LandingLayout/LandingLayout';
import LandingBanner from './components/LandingBanner/LandingBanner';
import LandingCarousel from './components/LandingCarousel/LandingCarousel';
import LandingAppealsMap from './components/LandingAppealsMap/LandingAppealsMap';
import LandingPolls from './components/LandingPolls/LandingPolls';
import LandingStatistics from './components/LandingStatistics/LandingStatistics';
import LandingInstructions from './components/LandingInstructions/LandingInstructions';
import LandingAppLinks from './components/LandingAppLinks/LandingAppLinks';
import ExtraMaterials from './components/ExtraMaterials/ExtraMaterials';
import LandingBottomModal from './components/LandingBottomModal/LandingBottomModal';

import './style.css';

const Landing = () => {
  const [showModal, setIsShowModal] = useState(true);

  return (
    <>
      <LandingBanner/>
      <LandingCarousel/>
      <LandingAppealsMap/>
      <LandingPolls/>
      <LandingStatistics/>
      <LandingInstructions/>
      <ExtraMaterials/>
      <LandingAppLinks/>
      {showModal && <LandingBottomModal onCloseModal={() => setIsShowModal(false)}/>}
    </>
  )
};

export default withLandingLayout(Landing);