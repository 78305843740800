import React from 'react';
import { YMaps } from 'react-yandex-maps'

import { config } from '../config';

import { AppContext } from '../store/AppContenxt/AppContenxt';
import AppRouter from './AppRouter';

const App = () => {

  return (
      <YMaps query={{ apikey: config.ymapsApiKey }}>
          <AppContext>
              <AppRouter/>
          </AppContext>
      </YMaps>
  );
};

export default App;
