import { toast } from 'react-toastify';

const ToastKind = {
  Success: 0,
  Warning: 1,
  Error: 2,
};

class Toaster {
  show(kind: any, message: string) {
    switch (kind) {
      case ToastKind.Warning:
        toast.warn(message);
        return;
      case ToastKind.Error:
        toast.error(message);
        return;
      default:
        toast.success(message);
    }
  }

  success(message: string): void {
    this.show(ToastKind.Success, message);
  }

  warning(message: string): void {
    this.show(ToastKind.Warning, message);
  }

  error(message: string): void {
    this.show(ToastKind.Error, message);
  }
}

export const toaster = new Toaster();