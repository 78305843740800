import appleStoreLogo from 'src/assets/img/new-landing/apple-store.svg'
import googleStoreLogo from 'src/assets/img/new-landing/google-store.svg'
import gosuslugiLogo from 'src/assets/img/new-landing/gosuslugi-logo.svg'
import gosuslugiLogoText from 'src/assets/img/new-landing/gosuslugi-text-icon.svg'
import ruStoreLogo from 'src/assets/img/new-landing/ru-store.svg'
import appGalleryStoreLogo from 'src/assets/img/new-landing/app-gallery-store.svg'
import {
  APPLE_APP_LINK,
  GOOGLE_PLAY_APP_LINK,
  REQUEST_TO_DEVELOPERS_LINK,
  REQUEST_TO_SUPPORT_LINK,
  RUSTORE_LINK,
  APP_GALLERY_LINK
} from '../../../../constants/links'

import { useGetOS } from 'src/hooks/useGetOS'

import './landingAppLinks.css'

const LandingAppLinks = () => {
  const { isIOS, isMacOS } = useGetOS()

  return (
    <div className='wrapper applinks-wrapper'>
      <div className='container'>
        <div className='applinks-content'>
          <div className='gos-logo'>
            <img src={gosuslugiLogo} alt='' />
            <img src={gosuslugiLogoText} alt='' />
          </div>
          <div className='app-links'>
            <p className='app-links__head'>Скачать приложение «Госуслуги Решаем вместе»</p>
            <div className='links-container'>
              <a rel='noreferrer' target='_blank' href={APPLE_APP_LINK}>
                <img className='app-img' src={appleStoreLogo} alt='Логотип AppStore' />
              </a>
              {!isIOS && !isMacOS && (
                <>
                  <a rel='noreferrer' target='_blank' href={GOOGLE_PLAY_APP_LINK}>
                    <img className='app-img' src={googleStoreLogo} alt='Логотип Google Play' />
                  </a>
                  <a rel='noreferrer' target='_blank' href={RUSTORE_LINK}>
                    <img className='app-img' src={ruStoreLogo} alt='Логотип RuStore' />
                  </a>
                  <a rel='noreferrer' target='_blank' href={APP_GALLERY_LINK}>
                    <img className='app-img' src={appGalleryStoreLogo} alt='Логотип AppGallery' />
                  </a>
                </>
              )}
            </div>
          </div>
          <div className='additional-links'>
            {/* @todo: the link below is different from the next one. check if we really need to remove it */}
            {/* <a rel='noreferrer' target='_blank' href={REQUEST_TO_DEVELOPERS_LINK}>
              Обратиться к разработчикам
            </a> */}
            <a rel='noreferrer' target='_blank' href={REQUEST_TO_SUPPORT_LINK}>
              Обратиться в поддержку
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingAppLinks
