import UIDropdown from 'src/components/ui/UIDropdown/UIDropdown';
import { DISCLAIMER_LINK } from '../../../../constants/links';
import './landingInstructions.css';
import { getPOSLink } from 'src/utils/redirect';

const LandingInstructions = () => {
  return (
    <section id='help' className='wrapper instructions-wrapper'>
      <div className='container'>
        <div className='instructions'>
          <div className='instructions-headline'>
            <h3>Как это работает</h3>
          </div>
          {/* add expanded={true} attribute to expand by default */}
          <UIDropdown title={'Где заполнить форму обратной связи'}>
            <ul className='where-to'>
              <li><a href={getPOSLink()} rel='noreferrer' target='_blank'>На	Госуслугах</a></li>
              <li>В приложении «Госуслуги Решаем вместе»</li>
              <li>На сайте ведомства, к компетенции которого относится вопрос</li>
            </ul>
          </UIDropdown>

          <UIDropdown title={'Как отправить сообщение или обращение'}>
            <p>Опишите проблему, укажите адрес, приложите фото или видео</p>
            <p>Сообщение или обращение рассмотрит профильный орган власти или администрация муниципалитета, отвечающие за решение этих вопросов. Без ответа оно не останется — ведомства обязаны дать ответ на сообщение в течение 10 дней, а на обращение — в течение 30 дней</p>
            <p>Затем вы сможете оценить результат</p>
          </UIDropdown>

          <UIDropdown title={'На что можно пожаловаться'}>
            <p>На любую проблему. Сейчас чаще всего сообщают о проблемах с электронной записью к врачу и электронными медицинскими документами, жалуются на дорожные ямы или мусор во дворе дома</p>
            <p>Вы можете узнать, в какое ведомство направлено сообщение или обращение, о сроках решения вопроса и получить ответ на Госуслугах или в мобильном приложении «Госуслуги Решаем вместе»</p>
            <p>Иногда жалоба может быть отклонена — например, если текст содержит угрозы, оскорбления или персональные данные третьих лиц. Подробнее о причинах отклонения — на <a href={DISCLAIMER_LINK} rel='noreferrer' target='_blank'>Госуслугах</a></p>
          </UIDropdown>

          <UIDropdown title={'Сколько ждать ответ'}>
            <p>Срок рассмотрения обращения до 30 дней, срок рассмотрения сообщения до 10 дней</p>
            <p>Если проблема не решена или вас не устроило качество ответа, сообщение и обращение можно вернуть на повторное рассмотрение. Для этого нажмите кнопку «вернуть на доработку» и добавьте комментарий</p>
          </UIDropdown>

          <UIDropdown title={'Правила подачи сообщений и обращений'}>
            <p>При направлении сообщения или обращения рекомендуется указывать информацию только об одной проблеме или предложении, так вопрос быстрее дойдет до адресата. Фотографии должны быть в хорошем разрешении, без дефектов и обработки в фоторедакторах. С полным текстом правил подачи сообщений и обращений можно ознакомиться по <a href={DISCLAIMER_LINK}>ссылке</a></p>
          </UIDropdown>
        </div>
      </div>
    </section>
  )
};

export default LandingInstructions;
