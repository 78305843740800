import React, { FC, MouseEvent, memo, useCallback } from 'react';
import cx from 'classnames';
import { TRegionDTO } from '../../../../types';


import styles from './RegionList.module.css';

interface IProps {
    onChange: (value: TRegionDTO) => void;
    regions: TRegionDTO[];
};

export const RegionList: FC<IProps> = memo( (props) => {
    const { onChange, regions } = props;

    const handleChange = useCallback((val) => (e: MouseEvent<HTMLLIElement>) =>  {
        e.stopPropagation();
        onChange(val);
    }, [onChange]);

    return (
        <>
            {regions.map((elem) => (
                <li
                    key={elem.id}
                    className={cx(styles.regionItem)}
                    onClick={handleChange(elem)}
                >
                    {elem.name}
                </li>
            ))}
        </>
    );
});
