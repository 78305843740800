import React, { FC, memo, useCallback, useRef, useState, useEffect } from 'react';
import { withYMaps } from 'react-yandex-maps';
import cx from 'classnames';

import { useFetchRegions } from '../../hooks/useFetchRegions';
import { useFetchLocation } from '../../hooks/useFetchLocation';
import { useAppContext } from '../../store/AppContenxt/AppContenxt';

import { RegionList } from './components/RegionList/RegionList';

import locationIcon from '../../assets/img/landing/location.svg';

import { UIInput } from '../ui/UIInput/UIInput';
import { UIScrollBar } from '../ui/UIScrollBar/UIScrollBar';
import { TRegionDTO } from '../../types';

import styles from './RegionSelector.module.css';

const DEFAULT_REGION_NAME = 'Москва';

const RegionSelector: FC<any> = memo((props) => {
  const { ymaps, showLocationIcon = true, className } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const { response: regionsList } = useFetchRegions();
  const [list, setList] = useState<null | TRegionDTO[]>(null);
  const regionsRef = useRef<null | HTMLDivElement>(null);
  const { response: currentRegion } = useFetchLocation(ymaps);
  const { store, updateStore } = useAppContext();

  useEffect(() => {
    setList(regionsList);
  }, [regionsList]);

  useEffect(() => {
    if (currentRegion && regionsList) {
      setCurrentRegionToStore();
    }
  }, [currentRegion, regionsList]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [regionsRef]);

  const handleToggle = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSearch = useCallback((val: string): void => {
    setSearch(val);
    if (regionsList) {
      setList(regionsList.filter((region) => region.name.toUpperCase().includes(val.toUpperCase())));
    }
  }, [regionsList]);

  const handleClickOutside = useCallback((event): void => {
    if (regionsRef.current && !regionsRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, [regionsRef, isOpen]);

  const handleChange = useCallback(async (value: TRegionDTO) => {
    if (updateStore) {
      updateStore({ currentRegion: value });
      setIsOpen(false);
    }
  }, []);

  const setCurrentRegionToStore = useCallback(async () => {
    const data = regionsList?.find((region) => region.name === currentRegion);
    const DEFAULT_REGION = regionsList?.find((region) => region.name === DEFAULT_REGION_NAME);

    if (!data && updateStore) {
      return updateStore({ currentRegion: DEFAULT_REGION })
    }

    if (updateStore) {
      updateStore({ currentRegion: data });
    }
  }, [currentRegion, regionsList]);

  return (
    <div className={cx(styles.currentRegionWrapper, isOpen ? styles.currentRegionWrapper_opened : '', className)}>
      {showLocationIcon &&
        <img
          src={locationIcon}
          alt='location'
        />
      }
      <div
        className={styles.currentRegion}
        onClick={handleToggle}
      >
        {store?.currentRegion?.name}
      </div >

      {isOpen && (
        <div
          className={styles.wrapper}
          ref={regionsRef}
        >
          <UIInput
            value={search}
            onChange={handleSearch}
            autoComplete='off'
          />
          <div className={styles.regionsListWrapper}>
            <UIScrollBar >
              <ul className={styles.regionsList}>
                {!!list?.length && (
                  <RegionList
                    regions={list}
                    onChange={handleChange}
                  />
                )}
              </ul >
            </UIScrollBar >
          </div >
        </div >
      )}
    </div >
  );
});

export default withYMaps(RegionSelector, false, ['geolocation', 'geocode']);
